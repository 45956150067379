// react dependencies
import React, { useRef, useEffect } from 'react';
import { TimelineLite, CSSPlugin, gsap } from 'gsap';

// components
import NavigationLink from '../../reusable/NavigationLink/NavigationLink';
import SocialButton from '../../reusable/SocialButton/SocialButton';

// styles
import './LeftPane.scss';

// images
import illustration_01 from '../../../images/illustration_1.svg';
import illustration_02 from '../../../images/illustration_2.svg';
import github from '../../../images/github.png';
import linkedin from '../../../images/linkedin.png';
import logo from '../../../images/logo.svg';

const LeftPane = props => {
	let currentPath = props.currentPath, illustration;
	if (currentPath === "/about") illustration = illustration_01;
	else if (currentPath === "/projects") illustration = illustration_02;

	// refs for the items i want to animate in the left pane
	const navParent = useRef(null);
	const imageParent = useRef(null);

	const socialIcons = [
		{ name: "Github", link: "https://github.com/Ashrafin", imagePath: github },
		{ name: "LinkedIn", link: "https://www.linkedin.com/in/ashrafin-h-597056105", imagePath: linkedin }
	];

	gsap.registerPlugin(CSSPlugin);

	useEffect(() => {
		// console.log(props);
		// change left pane to be visible, this removes the effect where it show the content for a brief second then starts the animation
		document.querySelector(".left-pane").style.visibility = "visible";
		// create new timeline
		let tl = new TimelineLite();
		// animate the left pane and the navigation items
		tl.from(".left-pane", 1, { y: "-100%", opacity: 0, delay: 0, ease: "power4" }, 0.1)
			.from([navParent.current.childNodes], 1.25, { y: "-30px", opacity: 0, delay: 1.25, ease: "power4", stagger: { amount: 0.35 } }, 0.1, "startLeftPaneAnimation")
		// animate the svg image and have it start at the same time as the navigation items
		tl.from([imageParent.current.childNodes], 1.25, { y: "100px", opacity: 0, delay: 1.25, ease: "power4" }, 0.1, "startLeftPaneAnimation")
		// animate the svg image to make it seem like its floating
		tl.to([imageParent.current.childNodes], 1.25, { y: "-15px", delay: 1.25, ease: "power0", repeat: -1, yoyo: true, yoyoEase: true }, 0.5)
	}, [props.currentPath, props]);

	return (
		<div style={{ visibility: "hidden" }} className="col-lg-6 bg-blue left-pane">
			<div className="container">
				<nav ref={ navParent } className="nav justify-content-end align-items-center position-relative">
					<img className="logo" src={ logo } alt="logo" />
					<NavigationLink name="About" link="about" />
					<NavigationLink name="Projects" link="projects" />
					{socialIcons.map((social, index) => {
						return <SocialButton name={ social.name } link={ social.link } imagePath={ social.imagePath } key={ index } />
					})}
				</nav>
			</div>

			<div className="container px-0 illustration-container">
				<div ref={ imageParent } className="d-flex illustration-box">
					<img className="image" src={ illustration } alt="illustration" />
				</div>
			</div>
		</div>
	);
};

export default LeftPane;
