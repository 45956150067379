// react dependencies
import React from 'react';

// components
import LeftPane from '../../components/general/LeftPane/LeftPane';
import RightPane from '../../components/general/RightPane/RightPane';

const About = props => {
	const dev_skills = [
		{ name: "HTML", mastery: "95" },
		{ name: "CSS", mastery: "85" },
		{ name: "JavaScript", mastery: "75" },
		{ name: "React", mastery: "75" },
		{ name: "Angular", mastery: "68" },
		{ name: "JQuery", mastery: "48" },
		{ name: "Node", mastery: "50" },
		{ name: "Express", mastery: "25" }
	];

	const design_skills = [
		{ name: "Sketch", mastery: "74" },
		{ name: "UI", mastery: "92" },
		{ name: "UX", mastery: "60" },
		{ name: "InVision", mastery: "70" }
	];

	return (
		<div className="row mx-0 w-100" style={{ height: "100%" }}>
			<LeftPane currentPath={ props.location.pathname } />
			<RightPane currentPath={ props.location.pathname } devSkills={ dev_skills } designSkills={ design_skills } />
		</div>
	);
};

export default About;
