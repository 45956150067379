// react dependencies
import React from 'react';

// components
import LeftPane from '../../components/general/LeftPane/LeftPane';
import RightPane from '../../components//general/RightPane/RightPane';

// images
import Moodys from '../../images/moodys.svg';
import Showroom from '../../images/showroom.svg';
import Maytrix from '../../images/maytrix.svg';
import Kickster from '../../images/kickster.svg';

const Projects = props => {
	const projects = [
		{ compName: "Moodys Analytics", roles: ["Web Developement", "Web Design", "UX Design"], tools: ["React", "JavaScript", "CSS", "HTML", "Sketch", "NodeJS", "Redux"], imagePath: Moodys, bg: "00279D" },
		{ compName: "Showroom", roles: ["IOS Design", "Web Design", "UX Design", "Web Developement"], tools: ["HTML", "CSS", "JavaScript", "Sketch", "React", "NodeJS", "Redux"], imagePath: Showroom, bg: "dc3545" },
		{ compName: "Maytrix Media", roles: ["UX Design", "IOS Design", "Web Design", "Web Development"], tools: ["HTML", "CSS", "JavaScript", "Sketch", "SailsJS", "NodeJS"], imagePath: Maytrix, bg: "2FD18C" },
		{ compName: "Kickster", roles: ["Web Design", "Web Developement", "UX Design", "IOS Design"], tools: ["HTML", "CSS", "JavaScript", "Sketch", "Angular", "NodeJS", "SailsJS"], imagePath: Kickster, bg: "C2C2C2" }
	];

	return (
		<div className="row mx-0 w-100" style={{ height: "100%" }}>
			<LeftPane currentPath={ props.location.pathname } />
			<RightPane currentPath={ props.location.pathname } projects={ projects } />
		</div>
	);
};

export default Projects;
