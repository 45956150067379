// react dependencies
import React, { useContext } from 'react';
import { Switch, Route, Redirect, __RouterContext } from 'react-router-dom';
import Analytics from 'react-router-ga';

// pages
import About from './pages/About/About';
import Projects from './pages/Projects/Projects';
import ErrorPage404 from './pages/ErrorPage404/ErrorPage404';

const Routes = () => {
	const { history } = useContext(__RouterContext);

	return (
		<Analytics id="UA-159560380-1">
			<Switch history={ history }>
				<Route exact path="/" render={ () => <Redirect from="/" to="/about" /> } />
				<Route exact path="/about" render={ props => <About { ...props } /> } />
				<Route exact path="/projects" render={ props => <Projects { ...props } /> } />
				<Route render={ props => <ErrorPage404 { ...props } /> } />
			</Switch>
		</Analytics>
	);
};

export default Routes;
