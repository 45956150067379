// react dependencies
import React, { useRef, useEffect } from 'react';
import { TimelineLite, CSSPlugin, gsap } from 'gsap';

// styles
import './ErrorPage404.scss';

// imagess
import illustration_03 from '../../images/illustration_3.svg';

const ErrorPage404 = props => {
	// refs for the items i want to animate in
	const image = useRef(null), text = useRef(null), button = useRef(null);

	gsap.registerPlugin(CSSPlugin);

	useEffect(() => {
		// change background to be visible, this removes the effect where it show the content for a brief second then starts the animation
		document.querySelector(".error-wrapper").style.visibility = "visible";
		// create new timeline
		let tl = new TimelineLite();
		// animate the background
		tl.from(".error-wrapper", 1, { y: "-100%", opacity: 0, delay: 0, ease: "power4" }, 0.1)
		// animate the image, text and button
		tl.from(image.current, 1.25, { y: "-100px", opacity: 0, delay: 1.25, ease: "power4" }, 0.2, "startAnimation")
			.from(text.current, 1.25, { y: "100px", opacity: 0, delay: 1.25, ease: "power4" }, 0.2, "startAnimation")
			.from(button.current, 1.25, { y: "100px", opacity: 0, delay: 1.25, ease: "power4" }, 1)
		// animate the svg image to make it seem like its floating
		tl.to(image.current, 1.25, { y: "-15px", delay: 1.25, ease: "power0", repeat: -1, yoyo: true, yoyoEase: true }, 1.5)
	}, [props]);

	return (
		<div style={{ visibility: "hidden" }} className="col-12 error-wrapper bg-blue">
			<img ref={ image } style={{ visibility: "inherit" }} className="img" src={ illustration_03 } alt="Error" />
			<h6 ref={ text } style={{ visibility: "inherit" }} className="text">Looks like you've reached a dead end.<br />The route you are looking for does not exist, lets head back.</h6>
			<button ref={ button } style={{ visibility: "inherit" }} onClick={ () => props.history.goBack() } className="btn btn-back">Go back</button>
		</div>
	);
};

export default ErrorPage404;
