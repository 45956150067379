// react dependencies
import React from 'react';

// styles
import './BubbleLabel.scss';

const BubbleLabel = props => {
	return (
		<span style={{ backgroundColor: `#${ props.bg }` }} className="bubble-label">{ props.name }</span>
	);
};

export default BubbleLabel;
