// react dependencies
import React from 'react';

// routes
import Routes from '../../../routes';

// styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

const App = () => {
  return (
    <section className="app container-fluid">
      <div className="row">
        <Routes />
      </div>
    </section>
  );
};

export default App;
