// react dependencies
import React from 'react';

// components
import BubbleLabel from '../BubbleLabel/BubbleLabel';

// styles
import './ProjectCard.scss';

const ProjectCard = props => {
	return (
		<>
			<div className="card px-0 project-card">
				<div className="card-img-container">
					<img style={{ backgroundColor: `#${ props.bg }` }} className="card-img" src={ props.imagePath } alt="company logo" />
				</div>

				<div className="card-info">
					<p className="company-name">{ props.compName }</p>
					<p className="roles">{ `${ props.roles.map(role => role).join(" / ") }` }</p>
				</div>
			</div>

			<div className="card card-img-overlay card-overlay-hover bg-dark">
				<div className="card-body">
					<span className="title">Tools Used:</span>
					<div className="tools">
						{props.tools.map((tool, index) => {
							return <BubbleLabel name={ tool } bg={ props.bg } key={ index } />
						})}
					</div>
				</div>
			</div>
		</>
	);
};

export default ProjectCard;
