// react dependencies
import React, { useRef, useEffect } from 'react';
import { TimelineLite, CSSPlugin, gsap } from 'gsap';

// components
import Header from '../../reusable/Header/Header';
import Indicators from '../../reusable/Indicators/Indicators';
import Bar from '../../reusable/Bar/Bar';
import ProjectCard from '../../reusable/ProjectCard/ProjectCard';

// styles
import './RightPane.scss';

const RightPane = props => {
	// refs for all the items i want to animate in the about page
	const aboutMeHeader = useRef(null), aboutMe = useRef(null), aboutDevSkillsHeader = useRef(null), aboutDevIndicators = useRef(null), aboutDevBars = useRef(null), aboutDesSkillsHeader = useRef(null), aboutDesIndicators = useRef(null), aboutDesBars = useRef(null);
	// about page content
	const aboutInfo = (
		<>
			<Header ref={ aboutMeHeader } name="About me" />
			<p ref={ aboutMe } className="intro-pitch">Hello I'm Ashrafin Hasib. I'm a frontend web developer and a web designer with more than 3 years of experience in designing, architecting and developing web applications. My main focus is cominging web application development with beautiful user interfaces to bring the best user experience possible.</p>
			<Header ref={ aboutDevSkillsHeader } name="Development Skills" />
			<Indicators ref={ aboutDevIndicators } />

			<div ref={ aboutDevBars } className="skills-wrapper">
				{props.devSkills ? props.devSkills.map((skill, index) => {
					return <Bar width={ skill.mastery } name={ skill.name } key={ index } />
				}) : null}
			</div>

			<Header ref={ aboutDesSkillsHeader } name="Design Skills" />
			<Indicators ref={ aboutDesIndicators } />

			<div ref={ aboutDesBars } className="skills-wrapper">
				{props.designSkills ? props.designSkills.map((skill, index) => {
					return <Bar width={ skill.mastery } name={ skill.name } key={ index } />
				}) : null}
			</div>
		</>
	);
	// refs for all the items i want to animate in the projects page
	const projectsHeader = useRef(null), projectsList = useRef(null);
	// projects page content
	const projectInfo = (
		<>
			<Header ref={ projectsHeader } name="My Portfolio" />
			<div ref={ projectsList } className="row mx-0">
				{props.projects ? props.projects.map((project, index) => {
					return (
						<div className="col-md-6 project-card-wrapper" key={ index }>
							<div style={{ backgroundColor: `#${ project.bg }` }} className="col-12 project-card-container">
								<ProjectCard compName={ project.compName } roles={ project.roles } tools={ project.tools } imagePath={ project.imagePath } bg={ project.bg } />
							</div>
						</div>
					)
				}) : null}
			</div>
		</>
	);

	gsap.registerPlugin(CSSPlugin);

	useEffect(() => {
		// change right pane to be visible, this removes the effect where it show the content for a brief second then starts the animation
		document.querySelector(".right-pane").style.visibility = "visible";
		// create new timeline
		let tl = new TimelineLite();
		// if the current path is the about page run the animations for the about page
		if (props.currentPath === "/about") {
			// animate the right pane, about me header and the about me info
			tl.from(".right-pane", 1, { y: "100%", opacity: 0, delay: 0, ease: "power4" }, 0.1)
				.from(aboutMeHeader.current, 1.25, { y: "-10px", opacity: 0, delay: 1.25, ease: "power4" }, 0.1, "startAboutAnimation")
				.from(aboutMe.current, 1.25, { y: "-10px", opacity: 0, delay: 1.25, ease: "power4" }, 0.3)
			// animate the development skills section and start it when the animation of the about me header starts
			tl.from(aboutDevSkillsHeader.current, 1.25, { y: "-10px", opacity: 0, delay: 1.25, ease: "power4" }, 0.1, "startAboutAnimation")
				.from([aboutDevIndicators.current.childNodes], 1.25, { x: "-10px", opacity: 0, delay: 1.25, ease: "power4", stagger: { amount: 0.5 } }, 0.1)
				.from([aboutDevBars.current.childNodes], 1.25, { x: "-10px", opacity: 0, delay: 0.75, ease: "power4", stagger: { amount: 0.75 } }, 1.5)
			// animate the design skills section and start it when the animation of the about me header stars
			tl.from(aboutDesSkillsHeader.current, 1.25, { y: "-10px", opacity: 0, delay: 1.25, ease: "power4" }, 0.1, "startAboutAnimation")
				.from([aboutDesIndicators.current.childNodes], 1.25, { x: "-10px", opacity: 0, delay: 1.25, ease: "power4", stagger: { amount: 0.5 } }, 0.1)
				.from([aboutDesBars.current.childNodes], 1.25, { x: "-10px", opacity: 0, delay: 0.75, ease: "power4", stagger: { amount: 0.75 } }, 1.5)
		}
		// if the current path is the projects page run the animations for the project page
		if (props.currentPath === "/projects") {
			// animate the right pane, projects header and the projects list
			tl.from(".right-pane", 1, { y: "100%", opacity: 0, delay: 0, ease: "power4" }, 0.1)
				.from(projectsHeader.current, 1.25, { y: "-10px", opacity: 0, delay: 1.25, ease: "power4" }, 0.1)
				.from([projectsList.current.childNodes], 1.25, { y: "20px", opacity: 0, delay: 1.25, ease: "power4", stagger: { amount: 0.5 } }, 0.5)
		}
	}, [props.currentPath, props]);

	return (
		<>
			<div style={{ visibility: "hidden" }} className="col-lg-6 bg-light-white right-pane">
				<div className="container">
					{ props.currentPath === "/about" ? aboutInfo : null }
					{ props.currentPath === "/projects" ? projectInfo : null }
				</div>
			</div>
		</>
	);
};

export default RightPane;
