// react dependencies
import React from 'react';

// styles
import './Indicator.scss';

const Indicator = props => {
	return (
		<div className="d-inline-flex indicator align-items-center">
			<span style={{ backgroundColor: `#${ props.color }` }} className="box"></span>
			<span className="name">{ props.name }</span>
		</div>
	);
};

export default Indicator;
