// react dependencies
import React from 'react';

// styles
import './Header.scss';

const Header = React.forwardRef((props, ref) => {
	return (
		<h1 ref={ ref } className="header">{ props.name }</h1>
	);
});

export default Header;
