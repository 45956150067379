// react dependencies
import React from 'react';
import { NavLink } from 'react-router-dom';

// styles
import './NavigationLink.scss';

const NavigationLink = props => {
	return (
		<li className="nav-item navigation-item">
			<NavLink to={{ pathname: `/${ props.link }` }} className="nav-link navigation-link">{ props.name }</NavLink>
		</li>
	);
};

export default NavigationLink;
