// react dependencies
import React from 'react';

// styles
import './SocialButton.scss';

const SocialButton = props => {
	return (
		<a href={ props.link } target="_blank" rel="noopener noreferrer" className="social-button-wrapper">
			<img className="img" src={ props.imagePath } alt={ props.name } />
		</a>
	);
};

export default SocialButton;
