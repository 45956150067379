// react dependencies
import React from 'react';

// components
import Indicator from '../Indicator/Indicator';

const Indicators = React.forwardRef((props, ref) => {
	return (
		<div ref={ ref } className="indicators-wrapper">
			<Indicator color="06D6A0" name="Jedi" />
			<Indicator color="26547C" name="Ninja" />
			<Indicator color="FFD166" name="Geek" />
			<Indicator color="EF476F" name="Newbie" />
		</div>
	);
});

export default Indicators;
