// react dependencies
import React, { useEffect, useState } from 'react';

// styles
import './Bar.scss';

const Bar = props => {
	const [width, setWidth] = useState(0);

	useEffect(() => {
		const timer = setTimeout(() => {
			setWidth(props.width);
		}, 2200);
		return () => clearTimeout(timer);
	}, [props.width]);

	let color;

	if (width > 75) color = "06D6A0";
	else if (width > 50 && width < 76) color = "26547C";
	else if (width > 25 && width < 51) color = "FFD166";
	else color = "EF476F";

	return (
		<div className="skill-level">
			<div className="label-container">
				<p className="label">{ props.name }</p>
			</div>
			<div className="progress bar">
				<div className="progress-bar" role="progressbar" style={{ width: `${ width }%`, backgroundColor: `#${ color }` }} aria-valuenow={ width } aria-valuemin="0" aria-valuemax="100"></div>
			</div>
		</div>
	);
};

export default Bar;
